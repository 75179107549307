import isEmpty from './is-empty'

function checkRequired(
  body,
  fields,
  t,
  returnArray = false
): {
  errors: [] | object
  isValid: boolean
} {
  const errors: {
    [key: string]: string
  } = {}
  const array: string[] = []
  const emptyText = t('is empty')
  fields.forEach((field) => {
    if (!body[field] || isEmpty(body[field])) {
      errors[field] = field + ' ' + emptyText
      const error: string = errors[field]
      array.push(error)
    }
  })
  return {
    errors: returnArray ? array : errors,
    isValid: !array.length,
  }
}
export default checkRequired

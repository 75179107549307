import React from 'react'
import TranslationItemButtons from './TranslationItemButtons'

const CustomType = ({ item, id, deleteItem, editItem }) => {
  return (
    <div className="form-group">
      <svg
        width="32"
        height="40"
        viewBox="0 0 32 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.66667 40H29.4444C30.3649 40 31.1111 39.2538 31.1111 38.3333V11.8015C31.1111 11.3594 30.9355 10.9355 30.623 10.623L20.4882 0.488155C20.1756 0.175595 19.7517 0 19.3096 0H1.66667C0.746192 0 0 0.746193 0 1.66667V38.3333C0 39.2538 0.746191 40 1.66667 40Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.66667 39.1837H29.4444C29.9141 39.1837 30.2948 38.803 30.2948 38.3333V11.8015C30.2948 11.5759 30.2052 11.3597 30.0457 11.2002L19.9109 1.06539C19.7515 0.905916 19.5352 0.816327 19.3096 0.816327H1.66667C1.19704 0.816327 0.816326 1.19704 0.816326 1.66667V38.3333C0.816326 38.803 1.19704 39.1837 1.66667 39.1837ZM20.4882 0.488155L30.623 10.623C30.9355 10.9355 31.1111 11.3594 31.1111 11.8015V38.3333C31.1111 39.2538 30.3649 40 29.4444 40H1.66667C0.746191 40 0 39.2538 0 38.3333V1.66667C0 0.746193 0.746192 0 1.66667 0H19.3096C19.7517 0 20.1756 0.175595 20.4882 0.488155Z"
          fill="#E9E9E9"
        />
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.54077 6.53061C4.54077 6.19248 4.81488 5.91837 5.15302 5.91837H17.3979C17.736 5.91837 18.0102 6.19248 18.0102 6.53061C18.0102 6.86874 17.736 7.14286 17.3979 7.14286H5.15302C4.81488 7.14286 4.54077 6.86874 4.54077 6.53061Z"
          fill="#9D9D9D"
        />
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.54077 9.79592C4.54077 9.45778 4.81488 9.18367 5.15302 9.18367H17.3979C17.736 9.18367 18.0102 9.45778 18.0102 9.79592C18.0102 10.134 17.736 10.4082 17.3979 10.4082H5.15302C4.81488 10.4082 4.54077 10.134 4.54077 9.79592Z"
          fill="#9D9D9D"
        />
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.54077 14.6939C4.54077 14.3557 4.81488 14.0816 5.15302 14.0816H26.3775C26.7156 14.0816 26.9897 14.3557 26.9897 14.6939C26.9897 15.032 26.7156 15.3061 26.3775 15.3061H5.15302C4.81488 15.3061 4.54077 15.032 4.54077 14.6939Z"
          fill="#9D9D9D"
        />
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.54077 17.9592C4.54077 17.6211 4.81488 17.3469 5.15302 17.3469H26.3775C26.7156 17.3469 26.9897 17.6211 26.9897 17.9592C26.9897 18.2973 26.7156 18.5714 26.3775 18.5714H5.15302C4.81488 18.5714 4.54077 18.2973 4.54077 17.9592Z"
          fill="#9D9D9D"
        />
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.54077 21.2245C4.54077 20.8864 4.81488 20.6122 5.15302 20.6122H26.3775C26.7156 20.6122 26.9897 20.8864 26.9897 21.2245C26.9897 21.5626 26.7156 21.8367 26.3775 21.8367H5.15302C4.81488 21.8367 4.54077 21.5626 4.54077 21.2245Z"
          fill="#9D9D9D"
        />
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.54077 24.4898C4.54077 24.1517 4.81488 23.8775 5.15302 23.8775H26.3775C26.7156 23.8775 26.9897 24.1517 26.9897 24.4898C26.9897 24.8279 26.7156 25.102 26.3775 25.102H5.15302C4.81488 25.102 4.54077 24.8279 4.54077 24.4898Z"
          fill="#9D9D9D"
        />
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.54077 27.7551C4.54077 27.417 4.81488 27.1429 5.15302 27.1429H26.3775C26.7156 27.1429 26.9897 27.417 26.9897 27.7551C26.9897 28.0932 26.7156 28.3673 26.3775 28.3673H5.15302C4.81488 28.3673 4.54077 28.0932 4.54077 27.7551Z"
          fill="#9D9D9D"
        />
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.54077 31.0204C4.54077 30.6823 4.81488 30.4082 5.15302 30.4082H26.3775C26.7156 30.4082 26.9897 30.6823 26.9897 31.0204C26.9897 31.3585 26.7156 31.6326 26.3775 31.6326H5.15302C4.81488 31.6326 4.54077 31.3585 4.54077 31.0204Z"
          fill="#9D9D9D"
        />
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.54077 34.2857C4.54077 33.9476 4.81488 33.6735 5.15302 33.6735H26.3775C26.7156 33.6735 26.9897 33.9476 26.9897 34.2857C26.9897 34.6238 26.7156 34.898 26.3775 34.898H5.15302C4.81488 34.898 4.54077 34.6238 4.54077 34.2857Z"
          fill="#9D9D9D"
        />
        <path
          opacity="0.2"
          d="M23.8889 11.6667C23 11.6667 21.8519 11.2963 21.3889 11.1111L25 10.2778L30.5556 11.1111C30.7408 11.6667 31.0556 12.6667 30.8334 12.2222C30.5556 11.6667 25 11.6667 23.8889 11.6667Z"
          fill="black"
        />
        <path
          d="M21.6667 11.1111H31.1111L20 0V9.44444C20 10.3649 20.7462 11.1111 21.6667 11.1111Z"
          fill="#27AE60"
        />
        <path
          d="M29.7223 11.1111C30.6112 11.1111 31.0186 12.4074 31.1112 13.0556V11.1111H29.7223Z"
          fill="#8BFFBC"
        />
      </svg>
      <p>{item.name}</p>
      <TranslationItemButtons item={item} id={id} deleteItem={deleteItem} editItem={editItem} />
    </div>
  )
}
export default CustomType

import { Trans } from 'react-i18next'

export default [
  {
    id: 1,
    label: <Trans>Contacts</Trans>,
    active: true,
    done: false,
    sidebarHead: <Trans>WHAT'S INCLUDED</Trans>,
  },
  {
    id: 2,
    label: <Trans>Documents</Trans>,
    active: false,
    done: false,
    sidebarHead: <Trans>Summary</Trans>,
  },
  {
    id: 3,
    label: <Trans>Additional Information</Trans>,
    active: false,
    done: false,
    sidebarHead: <Trans>Summary</Trans>,
  },
  {
    id: 4,
    label: <Trans>Options</Trans>,
    active: false,
    done: false,
    sidebarHead: <Trans>Summary</Trans>,
  },
  {
    id: 5,
    label: <Trans>Place Order</Trans>,
    active: false,
    done: false,
    sidebarHead: <Trans>Summary</Trans>,
  },
]

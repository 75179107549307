import pairspacer from '../images/pairspacer.svg'
import MultiSelectInput from '../components/common/blocks/MultiSelectInput'
import UploadBlock from '../components/common/blocks/upload/UploadBlock'
import apiDirectory from '../api/directory'
import { useTranslation } from 'react-i18next'
import { useSetItemData } from '../hooks/useSetItemData'
import SelectInputWithCheck from '../components/common/blocks/SelectInputWithCheck'
import SummaryButton from '../components/common/blocks/SummaryButton'

const SecondStep = (props) => {
  const { addFiles, files, onDelete, changeData, data, setCalculated } = props
  const languages: any[] = useSetItemData(apiDirectory, false, true, '/languages') || []

  const { t } = useTranslation()

  // @ts-ignore
  return (
    <>
      <div className="guest__content--main">
        <div className="lang__pair">
          <div className="head__pair">
            <h2>{t('Chose language pair and upload documents')}</h2>
          </div>
          <div className="pair__picker">
            <div className="pair__container">
              <div className="head__pair">
                <div className="pair__double">
                  <div className="pair__elem">
                    <SelectInputWithCheck
                      name={'languages_from'}
                      options={languages}
                      changeHandler={(value) => {
                        setCalculated(false)
                        changeData('languages_from', value)
                      }}
                      value={languages.find(
                        (item) => parseInt(item.id) === parseInt(data.languages_from)
                      )}
                      placeholder={t('Translation from')}
                      search={true}
                      arrayName={undefined}
                      arrayIndex={undefined}
                    />
                  </div>
                  <div className="pair__spacer">
                    <img src={pairspacer} alt="pairspacer" />
                  </div>
                  <div className="pair__elem">
                    <MultiSelectInput
                      changeHandler={(value, name) => {
                        if (JSON.stringify(data.languages_to) !== JSON.stringify(value)) {
                          setCalculated(false)
                        }
                        changeData(name, value)
                      }}
                      name={'languages_to'}
                      options={languages}
                      value={data.languages_to}
                      placeholder={t('Translation to')}
                      showDone={false}
                      hideFirstClick={true}
                    />
                  </div>
                </div>
              </div>
              <div className="content__files">
                <UploadBlock
                  currentFiles={files}
                  onDelete={onDelete}
                  onSave={(files) => {
                    setCalculated(false)
                    addFiles(files)
                  }}
                  showUpload={true}
                />
              </div>
            </div>
          </div>
          <SummaryButton {...props} />
        </div>
      </div>
    </>
  )
}

export default SecondStep

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const ManualIcon = () => {
  const [show, setShow] = useState(false)
  const { t } = useTranslation()

  return (
    <div
      className="upload-manual"
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <button className="btn btn-preview-toggle">
        <strong className="btn-preview-toggle__text">{t('MR')}</strong>
      </button>

      <div className={`upload-manual-review ${show ? 'upload-manual-review-visible' : ''}`}>
        <b>{t('Size for manual review')}</b>
        <p>
          {t(
            'Our counter can’t estimate this file size, but no worries, we will review it manually, keep filling.'
          )}
        </p>
      </div>
    </div>
  )
}
export default ManualIcon

import { Trans } from 'react-i18next'
import checkedicon from '../../images/checkgreenlight.svg'
import { useSelector } from 'react-redux'

export const StaticSidebar = () => {
  const domainData = useSelector((state: any) => state.init.domainData)
  console.log(domainData)
  return !!domainData.sidebarRight ? (
    <div
      dangerouslySetInnerHTML={{ __html: domainData.sidebarRight }}
      className="summary__project--info"
    />
  ) : (
    <div className="summary__project--info">
      <ul>
        <li>
          <span>
            <img src={checkedicon} alt="checked" />
          </span>{' '}
          <Trans>Guaranteed USCIS Acceptance</Trans>
        </li>
        <li>
          <span>
            <img src={checkedicon} alt="checked" />
          </span>{' '}
          <Trans>Certificate of Translation Accuracy</Trans>
        </li>
        <li>
          <span>
            <img src={checkedicon} alt="checked" />
          </span>{' '}
          <Trans>100% Human Translation</Trans>
        </li>
        <li>
          <span>
            <img src={checkedicon} alt="checked" />
          </span>{' '}
          <Trans>Secure Document Handling</Trans>
        </li>
        <li>
          <span>
            <img src={checkedicon} alt="checked" />
          </span>{' '}
          <Trans>Electronic PDF Delivered via E-mail</Trans>
        </li>
        <li>
          <span>
            <img src={checkedicon} alt="checked" />
          </span>{' '}
          <Trans>Physical Copies Available</Trans>
        </li>
        <li>
          <span>
            <img src={checkedicon} alt="checked" />
          </span>{' '}
          <Trans>Notarization Available</Trans>
        </li>
      </ul>
    </div>
  )
}

import { toast } from 'react-toastify'
import { getI18n } from 'react-i18next'
import { AxiosResponse } from 'axios'

export function handleResponse(response: AxiosResponse | any, showSuccess): any {
  const i18n = getI18n()

  if (showSuccess) {
    toast.success(
      response?.data
        ? response.data.success || response.data
        : response?.message || i18n.t('Success!')
    )
  }

  if (response.results) {
    return response.results
  }

  if (typeof response.data !== 'undefined') {
    return response.data
  }

  return response
}

export function handleError(error) {
  if (error?.response?.status === 401) {
    toast.error(
      error?.response?.data
        ? error.response.data.error || error.response.data
        : error?.message || 'There was an error with your request'
    )
  }
  if (error?.response?.status === 403) {
    toast.error(
      error?.response?.data
        ? error.response.data.error || error.response.data
        : error?.message || 'There was an error with your request'
    )
  }
  if (error?.response?.status === 500) {
    toast.error(
      error?.response?.data
        ? error.response.data.error || error.response.data?.errors
        : error?.message?.errors || 'There was an error with your request'
    )
  }
  return false
}

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import simpleimage from '../images/simpleimage.svg'
import { RootState } from '../redux-store/reducers'
import Languages from './header/Languages'

const Header = () => {
  const { t } = useTranslation()
  const domainData = useSelector((state: RootState) => state.init.domainData)

  return (
    <header className="simplified__header ">
      <div className="container-fluid">
        <div className="outer__header">
          <div className="header__logo">
            <a href={`https://${domainData.host}`}>
              <img src={simpleimage} alt="simpleimgae" />
            </a>
            <Languages />
          </div>
          <div className="header__info">
            <p>
              <span>{t('Any questions?')}</span> {t('Call or Text:')}{' '}
              <a href={`tel:+${domainData.phone}`}>{domainData.phone}</a> {t('Email:')}{' '}
              <a href={`mailto:${domainData.email}`}>{domainData.email}</a>{' '}
            </p>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header

export function useEmbed() {
  const params = new URLSearchParams(window.location.search)
  const embed = params.get('embed') === 'true'

  if (embed) {
    document.body.classList.add('embed')
  }

  return embed
}

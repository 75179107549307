import React from 'react'
import TranslationItem from './TranslationItem'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const TranslationItems = ({ translations, deleteTranslationItem }) => {
  const deleteItem = (id, index) => {
    deleteTranslationItem(id, index)
  }

  const { t } = useTranslation()
  return (
    <>
      {!!(translations && Object.keys(translations).length) &&
        Object.keys(translations).map((key, index) => (
          <TranslationItem
            deleteItem={(id) => {
              if (!isNaN(translations[key].uploadProgress) && !translations[key].error) {
                toast.error(t('Please wait until file(s) are fully uploaded before deleting.'))
              } else {
                deleteItem(id, index)
              }
            }}
            item={translations[key]}
            id={key}
            key={key}
            editItem={undefined}
          />
        ))}
    </>
  )
}
export default TranslationItems

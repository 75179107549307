import React from 'react'

import TranslationItemButtons from './TranslationItemButtons'
import ManualIcon from './ManualIcon'
import getFileIconType from '../../../functions/getFileIconType'
import { useTranslation } from 'react-i18next'

const FileType = ({ item, deleteItem, id }) => {
  const { t } = useTranslation()

  return (
    <div className={`form-group ${item.error ? 'form-group_hint has-manual' : ''}`}>
      <img alt={item.name} src={getFileIconType(item.name)} />
      <p>{item.name}</p>
      {!item.uploadProgress && !item.words && item.words !== 0 && !item.error && (
        <span className="in-quote">
          <svg
            width="15"
            height="22"
            viewBox="0 0 15 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {' '}
            <path
              d="M12.8348 5.65519V4.13508H13.0592C13.6112 4.13659 14.0616 3.69388 14.07 3.14209V0.9945C14.0618 0.442373 13.6115 -0.000670517 13.0592 7.61807e-07H1.01061C0.458316 -0.000670517 0.00805534 0.442373 0 0.9945V3.14209C0.00839096 3.69388 0.458651 4.13659 1.01061 4.13508H1.23515V5.65519C1.23431 6.37513 1.5624 7.05598 2.12594 7.50389L6.16603 10.7339L2.20532 13.8062C1.6255 14.2529 1.28651 14.9445 1.28885 15.6767V17.3458H1.01061C0.458651 17.3443 0.00839096 17.787 0 18.3388V20.4864C0.00805534 21.0385 0.458316 21.4816 1.01061 21.4809H13.0592C13.6115 21.4816 14.0618 21.0385 14.07 20.4864V18.3388C14.0616 17.787 13.6112 17.3443 13.0592 17.3458H12.8885V15.6873C12.8852 14.9479 12.5388 14.2519 11.9509 13.8033L7.90968 10.7297L11.9447 7.50372C12.5081 7.05581 12.836 6.3748 12.8348 5.65519ZM1.07405 1.07405H12.996V3.06103H1.07405V1.07405ZM12.996 20.4069H1.07405V18.4199H1.73324C1.76412 18.4199 1.79533 18.4286 1.82772 18.4286C1.85995 18.4286 1.8915 18.4199 1.92237 18.4199H12.25C12.2807 18.4199 12.3123 18.4286 12.3445 18.4286C12.3769 18.4286 12.4082 18.4199 12.439 18.4199H12.996V20.4069ZM11.8145 15.6873V17.3458H2.3629V15.6767C2.36206 15.2766 2.54717 14.8989 2.86435 14.6549L7.03768 11.4176L11.3005 14.658C11.622 14.9031 11.812 15.2832 11.8145 15.6873ZM11.273 6.66496L7.03601 10.0529L2.79755 6.66496C2.4891 6.42061 2.3092 6.04872 2.3092 5.65519V4.13508H11.7608V5.65519C11.7608 6.04872 11.5812 6.42044 11.273 6.66496Z"
              fill="#3F88C5"
            />
          </svg>
          {t('In Quote')}
        </span>
      )}

      {!!item.error && <ManualIcon />}

      {!!(item.uploadProgress && !item.words) && (
        <>
          {item.uploadProgress < 100 && (
            <span className="uploading">
              <b style={{ color: '#00D06C' }}>
                <span>{t('uploaded')}</span> {item.uploadProgress}%
              </b>
            </span>
          )}
          {item.uploadProgress === 100 && !item.words && item.words !== 0 && !item.error ? (
            <>
              {item.pages ? (
                <span className="uploading">
                  <b>
                    <span>{t('counting')}:</span>{' '}
                    <b className={'loading-blue'}>{item.page ? item.page : 1}</b> of {item.pages}{' '}
                  </b>
                </span>
              ) : (
                <span className="uploading">
                  <b>
                    <span className={'loading'}>{t('preparing')}</span>
                  </b>
                </span>
              )}
              <div className="uploading-loadbar">
                <div className="meter">
                  <span style={{ width: '100%', backgroundColor: '#3F88C5' }} />
                </div>
              </div>
            </>
          ) : (
            <div className="uploading-loadbar">
              <div className="progress-bar">
                <div
                  className="progress-percentage"
                  style={{ width: !isNaN(item.uploadProgress) ? `${item.uploadProgress}%` : 0 }}
                />
              </div>
            </div>
          )}
        </>
      )}
      <TranslationItemButtons item={item} id={id} deleteItem={deleteItem} editItem={undefined} />
    </div>
  )
}
export default FileType

import SelectInput from '../components/common/blocks/SelectInput'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import LocationSearch from '../components/pages/LocationSearch'
import countries from '../constans/countries'
import Payment from '../components/payment/index'

const FifthStep = ({ totalPrice, changeData, placeOrder, data, loading }) => {
  const [country, setCountry] = useState(false)

  const { t } = useTranslation()

  const shippingRequired = data.options
    ? Object.keys(data.options)
        .map((key) => data.options[key])
        .filter((item) => item.shipping_required).length
    : false

  return (
    <>
      <div className="guest__content--main">
        {!!shippingRequired && (
          <div className="shipping__information--section">
            <div className="shipping__information--head">
              <h2>{t('Shipping information')}</h2>
              <p>{t('You will receive an E-mail once your order has been shipped.')}</p>
            </div>
            <div className="shipping__form">
              <form>
                <div className="field__custom">
                  <input
                    type="text"
                    placeholder={t('Receipient')}
                    onChange={(e) => changeData('recipient', e.target.value)}
                  />
                </div>
                <div className="city__group">
                  <div className="field__custom">
                    <SelectInput
                      name={'country'}
                      options={countries}
                      changeHandler={(value, name, arrayName, arrayIndex, data) => {
                        setCountry(data)
                        changeData('country', data.label || data.name)
                      }}
                      placeholder={t('Country')}
                      search={true}
                      className={undefined}
                      arrayName={undefined}
                      arrayIndex={undefined}
                      value={undefined}
                      labelImg={undefined}
                      required={undefined}
                      nameKey={undefined}
                      defaultOption={undefined}
                    />
                  </div>
                  <div className="field__custom">
                    <input
                      type="text"
                      placeholder={t('Zip code')}
                      onChange={(e) => changeData('zip_code', e.target.value)}
                    />
                  </div>
                </div>
                <div className="adress__group">
                  <div className="field__custom">
                    <LocationSearch country={country} changeData={changeData} />
                  </div>
                  <div className="field__custom">
                    <input
                      type="text"
                      placeholder={t('Apartment / Suite Number')}
                      onChange={(e) => changeData('apartment', e.target.value)}
                    />
                  </div>
                </div>
                <div className="phone__group">
                  <div className="field__custom">
                    <input
                      type="text"
                      placeholder={t('Phone number')}
                      onChange={(e) => changeData('phone', e.target.value)}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
        <div className="payment__method">
          <h2>{t('Payment method')}</h2>
          <Payment
            amount={totalPrice}
            placeOrder={placeOrder}
            data={data}
            loading={loading}
            shippingRequired={shippingRequired}
          />
        </div>
      </div>
    </>
  )
}

export default FifthStep

import {
  UPDATE_TRANSLATION,
  ADD_TRANSLATION,
  ADD_FILES,
  DELETE_TRANSLATION,
  SET_OCR_ENGINE,
  SET_DATA_FILE,
  SET_CROSS_PARAMS,
  SET_CALCULATING,
  SET_APP_LANGUAGES,
  SET_CURRENCY,
  SET_DOMAIN_DATA,
} from './types'

export const addTranslationItem = (item) => ({
  type: ADD_TRANSLATION,
  payload: item,
})

export const updateTranslationItem = (item) => ({
  type: UPDATE_TRANSLATION,
  payload: item,
})

export const deleteTranslationItem = (item) => ({
  type: DELETE_TRANSLATION,
  payload: item,
})

export const addFiles = (files) => ({
  type: ADD_FILES,
  payload: files,
})
export const setOcrEngine = (payload) => ({
  type: SET_OCR_ENGINE,
  payload: payload,
})

export const setDataFile = (payload) => ({
  type: SET_DATA_FILE,
  payload: payload,
})

export const setCrossParams = (payload) => ({
  type: SET_CROSS_PARAMS,
  payload: payload,
})

export const setCalculating = (payload) => ({
  type: SET_CALCULATING,
  payload: payload,
})

export const setAppLanguages = (languages) => {
  return {
    type: SET_APP_LANGUAGES,
    payload: languages,
  }
}

export const setCurrency = ({ name, symbol }) => {
  return {
    type: SET_CURRENCY,
    payload: { name, symbol },
  }
}

export const setDomainData = ({
  email,
  phone,
  host,
  MarketCheckoutBlocks,
}: {
  email: string
  phone: string
  host: string
  MarketCheckoutBlocks?: { page: string; position: string; value: string }[]
}) => {
  const sidebarRight = MarketCheckoutBlocks?.find(
    (item) => item.page === 'common' && item.position === 'sideright'
  )?.value

  return {
    type: SET_DOMAIN_DATA,
    payload: { email, phone, host, sidebarRight: sidebarRight },
  }
}

import { combineReducers } from 'redux'
import errorLoading from './errorLoading'
import translation from './translation'
import init from './init'

const rootReducer = combineReducers({
  init: init,
  translation: translation,
  errorLoading: errorLoading,
})

export default rootReducer

export type RootState = ReturnType<typeof rootReducer>

import { SET_APP_LANGUAGES, SET_CURRENCY, SET_DOMAIN_DATA } from '../actions/types'

interface IInitState {
  app_languages: any[]
  currency: {
    name: string
    symbol: string
  }
  domainData: {
    email?: string
    phone?: string
    host?: string
    sidebarRight?: string
  }
}

const initialState: IInitState = {
  app_languages: [],
  currency: {
    name: 'USD',
    symbol: '$',
  },
  domainData: {},
}

export default function (
  state = initialState,
  action: {
    type: string
    payload: any
  }
): IInitState {
  switch (action.type) {
    case SET_APP_LANGUAGES:
      return {
        ...state,
        app_languages: action.payload,
      }
    case SET_CURRENCY:
      return {
        ...state,
        currency: action.payload,
      }

    case SET_DOMAIN_DATA:
      return {
        ...state,
        domainData: action.payload,
      }
    default:
      return state
  }
}

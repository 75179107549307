import {
  SET_CALCULATING,
  SET_CROSS_PARAMS,
  SET_DATA_FILE,
  ADD_TRANSLATION_ITEM,
  DELETE_TRANSLATION_ITEM,
  UPDATE_TRANSLATION_ITEM,
  UPDATE_COUNTER,
  SET_OCR_ENGINE,
} from '../actions/types'
import uniqId from '../../utils/uniqId'

const initialState = {
  items: {},
  characters: 0,
  allCharacters: 0,
  words: 0,
  allDuplicateCharacters: 0,
  allNewCharacters: 0,
  duplicateCharacters: 0,
  newCharacters: 0,
  newWords: 0,
  duplicateWords: 0,
  crossCharacters: 0,
  allCrossCharacters: 0,
  crossWords: 0,
  crossSegments: 0,
  segments: 0,
  duplicateSegments: 0,
  newSegments: 0,
  ocrEngine: 'google',
  dataFile: false,
  calculating: false,
}

const translations = (state = initialState, action) => {
  switch (action.type) {
    case SET_OCR_ENGINE:
      return {
        ...state,
        ocrEngine: action.payload,
      }
    case SET_CALCULATING:
      return {
        ...state,
        calculating: action.payload,
      }
    case SET_DATA_FILE:
      return {
        ...state,
        dataFile: action.payload,
      }
    case ADD_TRANSLATION_ITEM:
      let newItems = {}

      if (Array.isArray(action.payload)) {
        for (const item of action.payload) {
          const id = item.id || createId(item)
          newItems = Object.assign(newItems, { [id]: action.payload })
        }
      } else {
        const id = action.payload.id || createId(action.payload.id)
        newItems = Object.assign(newItems, { [id]: action.payload })
      }
      newItems = Object.assign(state.items, newItems)
      return {
        ...state,
        items: newItems,
      }

    case UPDATE_TRANSLATION_ITEM:
      const updateItems = { ...state.items }
      if (action.payload.id) {
        updateItems[action.payload.id] = action.payload
      }
      return {
        ...state,
        items: updateItems,
      }
    case DELETE_TRANSLATION_ITEM:
      const items = { ...state.items }
      if (items[action.payload]) {
        delete items[action.payload]
      }
      return {
        ...state,
        items: items,
      }

    case SET_CROSS_PARAMS:
      const crossParams = {
        crossCharacters: action?.payload?.duplicateCharacters || 0,
        allCrossCharacters: action?.payload?.allDuplicateCharacters || 0,
        crossWords: action?.payload?.duplicateWords || 0,
        crossSegments: action?.payload?.duplicateSegments || 0,
      }
      return {
        ...state,
        ...crossParams,
      }
    case UPDATE_COUNTER:
      const data = {
        characters: 0,
        allCharacters: 0,
        words: 0,
        allDuplicateCharacters: 0,
        allNewCharacters: 0,
        duplicateCharacters: 0,
        newCharacters: 0,
        newWords: 0,
        duplicateWords: 0,
        segments: 0,
        duplicateSegments: 0,
        newSegments: 0,
      }
      if (state.items && Object.keys(state.items).length) {
        for (const key of Object.keys(state.items)) {
          const item = state.items[key]

          for (const dataKey of Object.keys(data)) {
            data[dataKey] = data[dataKey] + (item[dataKey] || 0)
          }
        }
      }
      return {
        ...state,
        ...data,
      }

    default:
      return state
  }
}

const createId = (item) => {
  const id = uniqId()

  return item
    ? {
        [id]: item,
      }
    : id
}

export default translations

import React, { useEffect, useRef, useState } from 'react'
import {
  capitalizeFirstLetter,
  getLabelFromString,
  removeFromArrayByValue,
} from '../../../functions/functions'
import { useTranslation } from 'react-i18next'
import useOutsideClick from '../../../utils/useOutsideClick'

interface MultiSelectInputProps {
  options
  name
  changeHandler
  value
  placeholder
  nameKey?: string
  innerValueGet?: boolean | string
  showDone?: boolean
  hideFirstClick?: boolean
}

const MultiSelectInput = (props: MultiSelectInputProps) => {
  const {
    options,
    name,
    changeHandler,
    value,
    placeholder,
    nameKey,
    showDone,
    hideFirstClick,
    innerValueGet = false,
  } = props

  const [items, setItems] = useState(options)
  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const [selected, setSelected] = useState(value ? getInnerValue(value, innerValueGet) : [])

  const node = useRef<HTMLDivElement>(null)

  useOutsideClick(node, () => {
    toggleSelect(false)
  })
  useEffect(() => {
    setItems(options)
  }, [options])

  const filterItems = (e) => {
    const newItems = options.filter((option) => {
      const optionName = nameKey ? option[nameKey] : option.name

      return (
        optionName.substr(0, e.target.value.length).toUpperCase() == e.target.value.toUpperCase()
      )
    })
    setItems(newItems)
  }

  const toggleSelect = (value) => {
    setIsOpenSelect(value)
  }

  const { t } = useTranslation()

  const select = (value) => {
    let tmp = [...selected]

    if (value.id) {
      if (tmp.find((item) => item.id === value.id)) {
        tmp = tmp.filter((item) => item.id !== value.id)
      } else {
        tmp.push(value)
      }
    } else {
      if (!tmp.includes(value)) tmp.push(value)
    }

    setSelected(tmp)

    if (hideFirstClick) {
      setIsOpenSelect(false)
    }
  }

  useEffect(() => {
    if (changeHandler) {
      changeHandler(selected, name)
    }
  }, [selected])

  const remove = (value) => {
    const tmp = [...selected]
    if (tmp.includes(value)) removeFromArrayByValue(tmp, value)
    setSelected(tmp)
  }

  // @ts-ignore
  return (
    <div className="select__two" ref={node}>
      <div className="select__multi--field">
        <div className="multi__container" onClick={() => toggleSelect(!isOpenSelect)}>
          {selected?.length ? (
            selected.map((item, index) => (
              <div className="multi__elem" key={index}>
                <p>
                  {typeof item === 'object'
                    ? capitalizeFirstLetter(item.label || item.name)
                    : getLabelFromString(item)}
                </p>
                <a href="#" data-multi="multi1" onClick={() => remove(item)}>
                  <img src="/images/removewhite.svg" alt="removewhite" />
                </a>
              </div>
            ))
          ) : (
            <p>{placeholder}</p>
          )}
        </div>
        <div className="multi__dropdown" style={{ display: isOpenSelect ? 'block' : 'none' }}>
          <div className="multi__search">
            <input type="text" placeholder={t('Search...')} onKeyUp={(e) => filterItems(e)} />
          </div>
          <div className="multi__list">
            <ul>
              {items.map((item, index) => {
                return (
                  <SelectInputOption
                    key={index}
                    index={index}
                    item={item}
                    selected={selected}
                    nameKey={nameKey}
                    handlerChange={select}
                  />
                )
              })}
            </ul>
          </div>
          {!!showDone && (
            <div className="multi__submit">
              <a
                href="#"
                onClick={(e) => {
                  toggleSelect(false)
                  e.preventDefault()
                }}
              >
                {t('Done')}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const SelectInputOption = ({ item, selected, handlerChange, index, nameKey }) => {
  const { t } = useTranslation()

  let labelValue = nameKey ? item[nameKey] : item.label || item.name || getLabelFromString(item)

  if (
    typeof labelValue === 'object' &&
    !React.isValidElement(labelValue) &&
    (typeof item.label !== 'undefined' || typeof item.name !== 'undefined')
  )
    labelValue = t('Not set')

  const isSelected = Array.isArray(selected)
    ? item.id
      ? selected.find((findItem) => item.id === findItem.id)
      : selected.includes(item)
    : selected === index

  return (
    <li key={index} className={'' + (isSelected ? 'multi__active' : '')}>
      <a onClick={() => handlerChange(item)} href="#">
        {item.img && <img src={item.img.disk_name || item.img} alt="" />}
        {item.icon && <img src={item.icon.disk_name || item.icon} alt="" />}
        <span>{labelValue}</span>
      </a>
    </li>
  )
}

const getInnerValue = (value, innerValueGet) => {
  let newValue = value && Array.isArray(value) ? [...value] : []
  if (innerValueGet) {
    newValue = newValue.map((item) => item[innerValueGet])
  }
  return newValue
}

export default MultiSelectInput

import { apiProvider } from './api-provider'
import { AxiosResponse } from 'axios'

export class ApiCore {
  public getAll: (url, config) => Promise<AxiosResponse<any> | boolean>
  public getSingle: (id, url) => Promise<AxiosResponse<any> | boolean>
  public post: (model, url, event?) => Promise<AxiosResponse<any> | boolean>
  public put: (model, url, event?, showSuccess?) => Promise<AxiosResponse<any> | boolean>
  public patch: (model) => Promise<AxiosResponse<any> | boolean>
  public remove: (id, url) => Promise<AxiosResponse<any> | boolean>
  constructor(options) {
    if (options.getAll) {
      this.getAll = (url, config) => {
        return apiProvider.getAll(options.url + (url ?? ''), config)
      }
    }

    if (options.getSingle) {
      this.getSingle = (id, url) => {
        return apiProvider.getSingle(options.url + (url ?? ''), id)
      }
    }

    if (options.post) {
      this.post = (model, url, event) => {
        return apiProvider.post(options.url + (url ?? ''), model, event)
      }
    }

    if (options.put) {
      this.put = (model, url, event, showSuccess) => {
        return apiProvider.put(options.url + (url ?? ''), model, event, showSuccess)
      }
    }

    if (options.patch) {
      this.patch = (model) => {
        return apiProvider.patch(options.url, model)
      }
    }

    if (options.remove) {
      this.remove = (id, url) => {
        return apiProvider.remove(options.url + (url ?? ''), id)
      }
    }
  }
}

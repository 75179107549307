import apiCheckout from '../api/checkout'
import { PaymentIntent, PaymentIntentDefault } from '../types/common'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { isEmail } from '../functions/isEmail'
import { UPLOAD_BUCKET_PATH } from '../constans/common'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../redux-store/reducers'

declare let window: any

const useOrder = ({
  data,
  paymentIntent,
  setPaymentIntent,
  loading,
  setLoading,
  totalPrice,
  orderId,
  setOrderId,
  translations,
  currentStep,
  setCurrentStep,
  stepsData,
  calculating,
  files,
  setFiles,
  pricing,
  setStep,
  calculated,
  setCalculated,
  setTotalPrice,
  setPricing,
  setData,
}) => {
  const { t } = useTranslation()
  const currency = useSelector((state: RootState) => state.init.currency)

  const changeData = (name, value) => {
    const newData = { ...data }

    newData[name] = value
    setData(newData)
  }

  const checkStepDataRequired = (step) => {
    let check = true

    if (step < currentStep) return check

    switch (true) {
      case currentStep === 1 && (!data.name || !data.email || !isEmail(data.email)):
        toast.error(t('Please enter both name and email.'))
        check = false
        break
      case currentStep === 2 && calculating:
        toast.error(t('Please wait, files calculating!'))
        check = false
        break
      case currentStep === 2 &&
        (!data.languages_from || !data.languages_to || !data.languages_to.length) &&
        (!files || !files[0]):
        toast.error(t('Please enter languages and upload files.'))
        check = false
        break
      case currentStep === 2 &&
        (!data.languages_from || !data.languages_to || !data.languages_to.length):
        toast.error(t('Please enter languages!'))
        check = false
        break
      case currentStep === 2 && (!files || !files[0]):
        toast.error(t('Please upload files!'))
        check = false
        break
      case !!(
        currentStep === 2 &&
        data.languages_to &&
        data.languages_to.find((item) => item.id === data.languages_from)?.id
      ):
        toast.error(t('Target languages should not include source language!'))
        check = false
        break
      case currentStep === 4 &&
        (!data.options || Object.keys(data.options).length !== pricing.options.length):
        toast.error(t('Please select all options'))
        check = false
        break
    }
    if (!check) {
      check = false
    }

    return check
  }

  const handleChangeStep = async (type, step) => {
    if (loading) return

    const calculationHasError = Object.keys(translations)
      .map((key) => translations[key])
      .filter((item) => item.error).length

    if (currentStep === 4 && calculationHasError) {
      const result = await placeOrder({ noPayment: true, emailChanged: false })
      if (!result.success) {
        return toast.error(t('There was an error in server.'))
      } else {
        return setCurrentStep(6)
      }
    }

    let newArr = [...stepsData]
    const update = type ? (type === 'next' ? currentStep + 1 : currentStep - 1) : step

    const check = checkStepDataRequired(update)

    if (!check) return

    const result = await estimatePrice(update)
    if (!result) return

    newArr = newArr.map((item, index) => {
      item.active = update === index + 1
      item.done = index < update
      return item
    })
    setStep(newArr)
    setCurrentStep(update)
  }

  const estimatePrice = async (nextStep) => {
    if (currentStep === 2 && nextStep === 3 && !calculated) {
      const formData = new FormData()
      formData.append('languages_from', data.languages_from || '')

      const languagesTo: any[] = data.languages_to || []
      if (languagesTo && Array.isArray(languagesTo)) {
        languagesTo.forEach((item, index) => {
          formData.append(`languages_to[${index}]`, item?.id)
        })
      }

      formData.append(
        'files',
        JSON.stringify(Object.keys(translations).map((key) => translations[key]))
      )

      setLoading(true)
      const host = window.location.host
      const result: any = await apiCheckout.put(formData, `/calculate?filterDomain=${host}`)
      setLoading(false)
      if (result.price) {
        setTotalPrice(result.price ? parseFloat(result.price).toFixed(2) : result.price)
      }
      setPricing(result)

      setData({ ...data, ...{ pricing_id: result.pricing_id }, ...{ pricing: result } })

      setCalculated(true)

      return !!result.price
    }
    return true
  }

  const placeOrder = async ({
    noPayment = false,
    emailChanged = false,
    elements = false,
    stripe = false,
    paypal = false,
  }) => {
    setLoading(true)

    try {
      const formData = new FormData()

      for (const fileKey of Object.keys(translations)) {
        const file: any = translations[fileKey]
        const filename = file?.name

        if (file) {
          formData.append(
            'files[]',
            `https://storage.googleapis.com/${process.env.REACT_APP_UPLOAD_BUCKET_NAME}/${UPLOAD_BUCKET_PATH}${filename}`
          )
        }
      }

      for (const key in data) {
        if (data[key]) {
          if (Array.isArray(data[key])) {
            if (data[key].length > 0) formData.append(key, JSON.stringify(data[key]))
          } else {
            if (typeof data[key] === 'object' && data[key] !== null) {
              formData.append(key, JSON.stringify(data[key]))
            } else {
              formData.append(key, data[key])
            }
          }
        }
      }
      formData.append('price', totalPrice.toFixed(2).toString())
      if (emailChanged) {
        changeData('email', emailChanged)
        formData.delete('emailChanged')
        formData.delete('email')
        if (typeof emailChanged === 'string') {
          formData.append('emailChanged', emailChanged)
          formData.append('email', emailChanged)
        }
      }

      if (noPayment) {
        formData.append('noPayment', '1')
      }

      const searchParams = new URLSearchParams(window.location.search)
      // Log the values
      searchParams.forEach(function (value, key) {
        formData.append(key, value)
      })

      let orderResult
      if (!orderId) {
        orderResult = await apiCheckout.post(formData, `/create`)
        if (!orderResult || !orderResult.order) {
          setLoading(false)
          return false
        }
        setOrderId(orderResult.order.id)
      }

      if (noPayment) {
        setLoading(true)
        const result = await apiCheckout.post({}, `/send/${orderId || orderResult?.order?.id}`)
        if (typeof window !== 'undefined') {
          window.dataLayer.push({
            event: 'order',
            value: 0,
            currency: currency.name,
            transaction_id: 'quote',
          })
        }
        setLoading(false)
        return result
      } else {
        if (paymentIntent.id && paymentIntent.status === 'succeeded') {
          return sendEmail()
        }
        if (stripe && typeof stripe !== 'boolean') {
          // @ts-ignore

          const paymentResult = await stripe.confirmPayment({
            elements,
            redirect: 'if_required',
          })

          // This point will only be reached if there is an immediate error when
          // confirming the payment. Otherwise, your customer will be redirected to
          // your `return_url`. For some payment methods like iDEAL, your customer will
          // be redirected to an intermediate site first to authorize the payment, then
          // redirected to the `return_url`.
          if (paymentResult.error) {
            console.log('error', paymentResult.error)
            if (
              (paymentResult.error && paymentResult.error.type === 'card_error') ||
              paymentResult.error.type === 'validation_error' ||
              paymentResult.error.type === 'invalid_request_error'
            ) {
              toast.error(paymentResult.error.message)
            } else {
              toast.error(t('An unexpected error occurred.'))
            }
          } else {
            if (paymentResult.paymentIntent) {
              setPaymentIntent(paymentResult.paymentIntent)
            }
            return sendEmail(orderResult?.order?.id, paymentResult.paymentIntent)
          }
        }
        if (paypal) {
          return sendEmail(orderResult?.order?.id, paypal)
        }
      }
      setLoading(false)
    } catch (e: any) {
      console.log('e', e)
      setLoading(false)
    }
  }

  const sendEmail = async (id?: undefined | number, paymentIntentNew?: PaymentIntent | any) => {
    const result: any = await apiCheckout.post(
      {
        paymentData: {
          paymentIntent: paymentIntent.id ? paymentIntent : paymentIntentNew,
        },
      },
      `/send/${orderId || id}`
    )

    if (result.success) {
      setLoading(false)
      await handleChangeStep(false, 6)

      if (typeof window !== 'undefined') {
        window.dataLayer.push({
          event: 'order',
          value: totalPrice.toFixed(2),
          currency: currency.name,
          transaction_id: orderId || id,
        })
      }
    }
    setLoading(false)
    return result
  }

  const addFiles = (newFiles) => {
    const setNewFiles: any = [...files]
    for (let i = 0, l = newFiles.length; i < l; i++) {
      setNewFiles.push(newFiles[i])
    }
    setFiles(setNewFiles)
  }

  useEffect(() => {
    let total = 0
    if (data.options) {
      Object.keys(data.options).forEach((key) => {
        const option = data.options && data.options[key]
        if (option && option.totalPrice) {
          total += option.totalPrice
        }
      })

      total = total + (pricing && pricing.price ? parseFloat(pricing.price) : 0)

      if (data && data.turnaround_time && data.turnaround_time.urgency_fee) {
        total = total + (total * data.turnaround_time.urgency_fee) / 100
      }
      total = parseFloat(
        typeof total === 'string' ? parseFloat(total).toFixed(2) : total.toFixed(2)
      )

      setTotalPrice(total)
    }
    if (orderId) {
      setOrderId(false)
    }
    if (paymentIntent.id) {
      setPaymentIntent(PaymentIntentDefault)
    }
  }, [data.options, data.turnaround_time])

  const deleteFile = (fileIndex) => {
    let newFiles: any = [...files]
    newFiles = newFiles.map((fileItem, index) => {
      if (index !== fileIndex) {
        return fileItem
      }
    })
    newFiles = newFiles.filter((fileItem) => fileItem)
    setFiles(newFiles)
    setCalculated(false)
  }

  return {
    handleChangeStep,
    sendEmail,
    estimatePrice,
    placeOrder,
    changeData,
    addFiles,
    deleteFile,
  }
}

export default useOrder

import { useState, useEffect } from 'react'

export const useSetItemData = (api, id, tr, url = '') => {
  const [data, setData] = useState([])
  useEffect(() => {
    if (id) {
      api.getSingle(id, url).then((response) => {
        setData(response)
      })
    } else {
      api.getAll(url).then((response) => {
        setData(response)
      })
    }
  }, [tr])

  return data
}

import { SET_ERROR, SET_LOADING } from '../actions/types'

type IErrorState = {
  error: any | string
  isLoading: boolean
}

const initialState: IErrorState = {
  error: '',
  isLoading: false,
}

const errorLoading = (state = initialState, action): IErrorState => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    default:
      return state
  }
}

export default errorLoading

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './css/custom.css'
import './css/permissions.css'
// import './css/upload.css';
import './css/freelance.css'
import './css/vendor.css'
import './css/updated.scss'
import './css/updates.scss'
import './css/style.css'
import './css/styles/app.scss'

import './App.scss'
import 'react-toastify/dist/ReactToastify.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './redux-store'
import './i18n'
import FormLoading from './components/common/blocks/FormLoading'
import TagManager from 'react-gtm-module'
import { GTM_DOMAINS } from './config/constants'

const tagManagerArgs = {
  gtmId: GTM_DOMAINS[window.location.host],
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<FormLoading loadingText={undefined} />}>
        <App />
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// @ts-ignore
reportWebVitals()
